













import { Ref, ref } from '@vue/composition-api';
import { keyBy } from 'lodash';

import IndexTable from '@/components/IndexTable.vue';
import { TransactionQueryInput, UserQueryInput } from '@/generated/graphql';
import { useUserGetters } from '@/store';
import { ALL_INVITE_HEADER } from '@/constants/portfolio';
import { REFFERERAL_LOG, REFERAL_LOG_USERS } from '@/services/graphql/queries';

export default {
  name: 'AllInvites',
  components: {
    IndexTable
  },

  setup(
    _props,
    {
      root: {
        $apolloProvider: {
          defaultClient: { query }
        }
      }
    }
  ) {
    const tableItems: Ref<any> = ref([]);
    query({
      query: REFFERERAL_LOG,
      variables: {
        input: {
          _id: useUserGetters(['getId']).getId.value
        } as TransactionQueryInput
      }
    }).then(({ data: { transaction } }) => {
      if (transaction?.referral)
        query({
          query: REFERAL_LOG_USERS,
          variables: {
            input: {
              OR: [...transaction.referral?.map(log => ({ email: log!.sentTo }))]
            } as UserQueryInput
          }
        }).then(({ data: { users } }) => {
          const usersByEmail = keyBy(users, 'email');
          tableItems.value = transaction.referral?.map(log => ({
            contact: log?.sentTo,
            date: log?.timestamp,
            status: usersByEmail[log!.sentTo!] ? 'Accepted' : 'Pending'
          }));
        });
    });

    return {
      header: ref(ALL_INVITE_HEADER),
      items: tableItems
    };
  }
};
